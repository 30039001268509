exports.components = {
  "component---src-dynamic-pages-service-page-js": () => import("./../../../src/dynamic-pages/service-page.js" /* webpackChunkName: "component---src-dynamic-pages-service-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-downloads-index-js": () => import("./../../../src/pages/downloads/index.js" /* webpackChunkName: "component---src-pages-downloads-index-js" */),
  "component---src-pages-gallerie-index-js": () => import("./../../../src/pages/gallerie/index.js" /* webpackChunkName: "component---src-pages-gallerie-index-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */)
}

